import React from 'react';
import {
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Link
} from '@mui/material';
import { MenuBook, PermMedia } from '@mui/icons-material';
import NextLink from 'next/link';

const NavigationMenu = () => {
  return (
    <MenuList component="nav">
      {/* <MenuItem sx={{ padding: "10px 8px" }} component="div">
        <ListItemIcon>
          <DashboardOutlined />
        </ListItemIcon>
        <ListItemText>ダッシュボード</ListItemText>
      </MenuItem> */}
      {/* <MenuItem sx={{ padding: "10px 8px" }} component="div">
        <ListItemIcon>
          <List />
        </ListItemIcon>
        <ListItemText>顧客リスト</ListItemText>
      </MenuItem> */}
      <NextLink href="/catalogs">
        <MenuItem sx={{ padding: '10px 8px' }}>
          <Link
            underline="none"
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: 'primary.contrastText'
            }}
          >
            <ListItemIcon>
              <MenuBook />
            </ListItemIcon>
            <ListItemText sx={{ color: 'primary.contrastText' }}>
              カタログ一覧
            </ListItemText>
          </Link>
        </MenuItem>
      </NextLink>
      <NextLink href="/media">
        <MenuItem sx={{ padding: '10px 8px' }}>
          <Link
            underline="none"
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: 'primary.contrastText'
            }}
          >
            <ListItemIcon>
              <PermMedia />
            </ListItemIcon>
            <ListItemText sx={{ color: 'primary.contrastText' }}>
              メディア一覧
            </ListItemText>
          </Link>
        </MenuItem>
      </NextLink>
      {/* <MenuItem
        onClick={handleClick}
        sx={{ padding: '10px 8px' }}
        component="div"
      >
        <ListItemIcon>
          <SettingsOutlined />
        </ListItemIcon>
        <ListItemText>設定</ListItemText>
        {open ? <ExpandLess /> : <ExpandMore />}
      </MenuItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <MenuItem sx={{ padding: '10px 8px ' }} component="div">
          <ListItemIcon />
          <ListItemText>シート</ListItemText>
        </MenuItem>
      </Collapse> */}
    </MenuList>
  );
};

export default NavigationMenu;
