import React, { PropsWithChildren } from 'react';
import { GlobalFooter } from '../GlobalFooter';
import { Box } from '@mui/material';

const MainPane = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Box
      sx={{
        display: 'flex',
        maxHeight: 'calc(100vh - 64px)',
        flexDirection: 'column',
        flexGrow: 1,
        overflow: 'scroll'
      }}
    >
      <Box
        component="main"
        sx={{
          padding: 4
        }}
      >
        <Box sx={{ minWidth: '1024px', marginX: 'auto' }}>{children}</Box>
      </Box>
      <GlobalFooter />
    </Box>
  );
};

export default MainPane;
