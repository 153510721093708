import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useAuth } from 'hooks/useAuth';
import { useRouter } from 'next/router';
import { Box } from '@mui/material';

const AuthLayout = ({ children }: PropsWithChildren<{}>) => {
  const { isLoggedIn } = useAuth();
  const router = useRouter();
  const [loggingIn, setLoggingIn] = useState<boolean | null>(null);

  useEffect(() => {
    const checkLogin = async () => {
      const loginStatus = await isLoggedIn();
      if (loginStatus) router.replace('/catalogs');
    };
    checkLogin();
  }, [isLoggedIn, router]);

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#f4f6f8'
      }}
    >
      {children}
    </Box>
  );
};

export default AuthLayout;
