import React from 'react';
import { getYear } from 'date-fns';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const GlobalFooter = () => {
  const firstYear = 2021;
  const thisYear = getYear(new Date());

  return (
    <footer>
      <Box sx={{ textAlign: 'center' }}>
        <p>
          <small>
            &copy;{firstYear} - {thisYear} SWODA Inc.
          </small>
        </p>
      </Box>
    </footer>
  );
};

const StyledGlobalFooter = styled(GlobalFooter)`
  margin-top: auto;
  display: block;
`;

export default StyledGlobalFooter;
