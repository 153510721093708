import { config } from '../config';
import useSWR from 'swr';
import { fetcher } from 'utils/fetcher';

type UserInfo = {
  organizationId: string;
  systemUserId: string;
  projectId: string;
  token: string;
  fqdn: string;
};

export const useUserInfo = () => {
  return useSWR<UserInfo>(`${config.authOrigin}/api/v1/auth/self`, fetcher);
};
