import React from 'react';
import {
  AppBar,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Toolbar,
  Typography
} from '@mui/material';
import AccountMenu from './AccountMenu';
import { Label } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import { ProjectSelector } from '../ProjectSelector';

const GlobalHeader = () => {
  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        borderBottom: '1px solid #a9a9c2',
        flexGrow: 0,
        bgcolor: 'primary.main'
      }}
      position="static"
    >
      <Toolbar>
        <Typography variant="h1" component="h1" color="#FFF">
          Catalog Talk
        </Typography>
        {/* <img src={logo.src} alt="JIDO" width={105} /> */}
        <Stack
          sx={{ ml: 'auto', alignItems: 'center' }}
          direction="row"
          spacing={2}
        >
          <ProjectSelector />
          <AccountMenu />
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default GlobalHeader;
