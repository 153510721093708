var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"b50266701d4732fb6d17784b8cf64efa6e63de30"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { BrowserTracing } from '@sentry/tracing';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const SENTRY_ENV =
  process.env.NEXT_PUBLIC_VERCEL_ENV ||
  process.env.SENTRY_ENV ||
  process.env.NEXT_PUBLIC_SENTRY_ENV;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://8f1e47bb591f4f95bac7f5557183895e@o4504472495718400.ingest.sentry.io/4504472499191808',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  integrations: [
    new BrowserTracing({
      // これにドメインを指定するとsentry-traceヘッダが付加されてCORSで死ぬ
      tracePropagationTargets: []
    })
  ],
  environment: SENTRY_ENV
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
