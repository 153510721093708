import { OpenInNew } from '@mui/icons-material';
import {
  Box,
  Chip,
  IconButton,
  LinearProgress,
  Paper,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { useCatalogDetail } from 'hooks/useCatalogDetail';
import { CopyToClipboard } from '../CopyToClipboard';

type Props = {
  catalogId: string;
};

const SelectedCatalogInfo = ({ catalogId }: Props) => {
  const catalogInfo = useCatalogDetail(catalogId);
  if (!catalogInfo) {
    return <LinearProgress />;
  }
  return (
    <Paper sx={{ margin: 'auto', p: 1, width: '100%' }}>
      {catalogInfo.data?.uiDetails.thumbnail ? (
        <Tooltip title={catalogInfo.data?.name ?? ''} arrow>
          <img
            src={catalogInfo.data?.uiDetails.thumbnail}
            alt={catalogInfo.data?.name}
            style={{ maxHeight: '100%', maxWidth: '100%' }}
          />
        </Tooltip>
      ) : (
        <Typography>{catalogInfo.data?.name}</Typography>
      )}
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Chip label="編集中" size="small" />
        <Stack flexDirection={'row'}>
          <CopyToClipboard
            targetText={catalogInfo.data?.publishedUrl ?? ''}
            defaultContent="カタログのURLをコピー"
            size="small"
            color="primary"
          />
          <Tooltip title="カタログを新しいタブで開く" arrow>
            <IconButton
              size="small"
              component="a"
              color="primary"
              href={catalogInfo.data?.publishedUrl}
              target="_blank"
            >
              <OpenInNew fontSize="small" />
            </IconButton>
          </Tooltip>
        </Stack>
      </Box>
    </Paper>
  );
};

export default SelectedCatalogInfo;
