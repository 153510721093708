import useSWR from 'swr';
import { Catalog } from 'types/Catalog';
import { fetcher } from 'utils/fetcher';

export const useCatalogDetail = (catalogId?: string) => {
  return useSWR<Catalog>(
    catalogId ? `/api/catalogs/${catalogId}` : null,
    fetcher
  );
};
