import { DefaultLayout } from '../components/Layouts';
import { useAuth } from 'hooks/useAuth';
import React, { useEffect } from 'react';
import { useRouter } from 'next/router';

function Index() {
  const { logout } = useAuth();
  const router = useRouter();

  useEffect(() => {
    router.replace('/catalogs');
  }, []);

  return <div></div>;
}

Index.getLayout = function getLayout(page: React.ReactElement) {
  return <DefaultLayout>{page}</DefaultLayout>;
};

export default Index;
