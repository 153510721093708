import React, { PropsWithChildren, useEffect } from 'react';
import { Box } from '@mui/material';
import { GlobalHeader } from '@/components/common/GlobalHeader';
import { Navigation } from '@/components/common/Navigation';
import { MainPane } from '../common/MainPane';
import { useAuth } from '../../hooks/useAuth';
import { useRouter } from 'next/router';

const DefaultLayout = ({ children }: PropsWithChildren<{}>) => {
  const { isLoggedIn } = useAuth();
  const router = useRouter();

  useEffect(() => {
    const checkLogin = async () => {
      const loginStatus = await isLoggedIn();
      if (!loginStatus) router.replace('/login');
    };
    checkLogin();
  }, [isLoggedIn, router]);

  return (
    <Box sx={{ height: '100vh', flexDirection: 'column', display: 'flex' }}>
      <GlobalHeader />
      <Box sx={{ display: 'flex', alignItems: 'stretch', flexGrow: 1 }}>
        <Navigation />
        <MainPane>{children}</MainPane>
      </Box>
    </Box>
  );
};

export default DefaultLayout;
