import { Link } from '@mui/icons-material';
import { ButtonProps, IconButton, Tooltip } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

type Props = {
  targetText: string;
  defaultContent?: string;
};
const CopyToClipboard = ({
  targetText,
  defaultContent = '',
  ...rest
}: Props & ButtonProps) => {
  const tooltipTimeoutRef = useRef<NodeJS.Timeout>(null);
  const [tooltipContent, setTooltipContent] = useState<string>(defaultContent);
  useEffect(() => {
    let tref = tooltipTimeoutRef.current;
    if (tref !== null) {
      tref = null;
    }
    if (tooltipContent !== defaultContent) {
      tref = setTimeout(() => {
        setTooltipContent(defaultContent);
      }, 1500);
    }
    return () => {
      tref = null;
    };
  }, [tooltipContent]);

  return (
    <Tooltip title={tooltipContent} arrow={true}>
      <IconButton
        {...rest}
        onClick={() =>
          navigator.clipboard
            .writeText(targetText)
            .then(() => setTooltipContent('カタログURLをコピーしました'))
        }
      >
        <Link />
      </IconButton>
    </Tooltip>
  );
};

export default CopyToClipboard;
