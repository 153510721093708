import * as React from 'react';
import {
  Box,
  Menu,
  MenuItem,
  ListItemIcon,
  Tooltip,
  IconButton,
  Link,
  ListItemText
} from '@mui/material';
import { Settings, Logout, AccountCircle } from '@mui/icons-material';
import { useAuth } from 'hooks/useAuth';
import NextLink from 'next/link';
import { useRouter } from 'next/router';

const AccountMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const router = useRouter();
  const { logout } = useAuth();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="アカウントメニュー">
          <IconButton onClick={handleClick}>
            <AccountCircle sx={{ color: '#FFF' }} />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 15,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          <NextLink href="/password">
            <Link underline="none">
              <ListItemText>パスワード変更</ListItemText>
            </Link>
          </NextLink>
        </MenuItem>
        <MenuItem onClick={() => logout().then(() => router.push('/login'))}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          <ListItemText>ログアウト</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default AccountMenu;
