import { apiClient } from 'utils/apiClient';
import Cookie from 'js-cookie';
import { useRouter } from 'next/router';
import { config } from '../config';
import { Organization } from 'types/Organization';
import { SystemUser } from 'types/systemUser';
import { Project } from 'types/Project';
import axios, { AxiosError } from 'axios';
import * as Sentry from '@sentry/nextjs';

type LoginResponse = {
  authToken: string;
  organization: Organization;
  systemUser: SystemUser;
  project: Project;
};

export const LOGIN_ERROR_MESSAGES = {
  LOGIN_FAILED: 'LOGIN_FAILED',
  UNKNOWN: 'UNKNOWN'
};

export const useAuth = () => {
  const { sessionKey } = config;
  const router = useRouter();

  const login = async (email: string, password: string) => {
    const response = await axios
      .post<LoginResponse>(`${config.authOrigin}/api/v1/auth/login`, {
        email,
        password
      })
      .catch((e: AxiosError) => {
        if (e.response?.status === 401) {
          throw new Error(LOGIN_ERROR_MESSAGES.LOGIN_FAILED);
        }
        Sentry.captureException(e);
        throw new Error(LOGIN_ERROR_MESSAGES.UNKNOWN);
      });
    const { data } = response;
    Cookie.set(sessionKey, data.authToken, {
      sameSite: 'lax',
      secure: true
    });
    if (data.authToken) {
      router.push('/catalogs');
    }
  };

  const isLoggedIn = async () => {
    try {
      await apiClient.get(`${config.authOrigin}/api/v1/auth/validate_token`);
      return true;
    } catch (_) {
      return false;
    }
  };

  const logout = async () => {
    try {
      await apiClient.delete(`${config.authOrigin}/api/v1/auth/logout`);
    } catch (e) {
      Sentry.captureException(e);
    } finally {
      Cookie.remove(sessionKey);
    }
  };

  const resetPassword = async (password: string, new_password: string) => {
    try {
      await apiClient.patch(`${config.authOrigin}/api/v1/auth/password`, {
        password,
        new_password
      });
    } catch (e: any) {
      Sentry.captureException(e);
      throw new Error(e.response.data.errorMessage);
    }
  };

  return {
    login,
    logout,
    isLoggedIn,
    resetPassword
  };
};
