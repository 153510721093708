import React from 'react';
import {
  Box,
  Paper,
  Divider,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import NavigationMenu from './NavigationMenu';
import { useRouter } from 'next/router';
import { SelectedCatalogInfo } from '../SelectedCatalogInfo';
import { OpenInNew } from '@mui/icons-material';

const Navigation = () => {
  const router = useRouter();
  const { catalogId = undefined } = router.query;
  return (
    <Paper
      elevation={1}
      square
      sx={{
        width: '256px',
        padding: 2
      }}
    >
      <Box
        my={2}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {/* <Typography variant="h4" component="h2" color="primary.contrastText">
          NAKANE YUKI
        </Typography>
        <Typography variant="body2" color="primary.contrastText">
          エンジニア
        </Typography> */}
      </Box>
      <Divider />
      <NavigationMenu />
      {catalogId && !Array.isArray(catalogId) && (
        <>
          <Divider />
          <Box py={1}>
            <SelectedCatalogInfo catalogId={catalogId} />
          </Box>
        </>
      )}
      <Divider />
      <MenuList dense disablePadding>
        <MenuItem
          disableRipple
          disableTouchRipple
          sx={{ px: 1 }}
          onClick={() => {
            window.open('https://swoda.co.jp/chatbot-request/', '_blank');
          }}
        >
          <ListItemText>チャットボットの設定</ListItemText>
          <ListItemIcon>
            <OpenInNew fontSize="small" />
          </ListItemIcon>
        </MenuItem>
      </MenuList>
    </Paper>
  );
};

export default Navigation;
