import { createTheme } from '@mui/material/styles';

import palette from './palette';
import typography from './typography';

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          height: '-webkit-fill-available'
        },
        body: {
          minHeight: '100vh',
          height: '-webkit-fill-available'
        }
      }
    }
  },
  palette,
  typography
});

export default theme;
