import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import Cookie from 'js-cookie';
import { config } from '../config';

// axios.defaults.withCredentials = true;

export const apiClient: AxiosInstance = axios.create({
  baseURL: config.apiOrigin,
  timeout: 5000
});

apiClient.interceptors.request.use(
  (options) => {
    if (Cookie.get(config.sessionKey)) {
      options.headers!['X-api-token'] = Cookie.get(config.sessionKey)!;
    }
    return options;
  },
  (error) => {
    return Promise.reject(error);
  }
);
