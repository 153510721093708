import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useProjectList } from 'hooks/useProjectList';
import { useUserInfo } from 'hooks/useUserInfo';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import theme from 'theme';
import { apiClient } from 'utils/apiClient';
import { config } from '../../../config';

const ProjectSelector = () => {
  const { data: projectList } = useProjectList();
  const { data: userInfo, mutate } = useUserInfo();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>('');

  useEffect(() => {
    if (userInfo) {
      setSelected(userInfo.projectId);
    }
  }, [userInfo]);

  const handleChangeProject = async (projectId: string) => {
    await apiClient.post(
      `${config.authOrigin}/api/v1/projects/switch_project`,
      {
        project_id: projectId
      }
    );
    mutate();
    window.location.href = '/';
    // router.reload();
  };
  if (!userInfo || !projectList) return <></>;
  return projectList.totalCount > 1 ? (
    <>
      <Select
        value={userInfo.projectId}
        sx={{ background: 'white' }}
        size="small"
        onChange={(e) => {
          setSelected(e.target.value);
          setIsDialogOpen(true);
        }}
      >
        {projectList.projects.map((p) => (
          <MenuItem
            key={p.projectId}
            value={p.projectId}
            disabled={p.projectId === userInfo.projectId}
          >
            {p.projectName}
          </MenuItem>
        ))}
      </Select>
      <Dialog open={isDialogOpen} maxWidth="sm" fullWidth>
        <DialogTitle>プロジェクトの切り替え確認</DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText>
            プロジェクトを切り替えます。よろしいですか？
          </DialogContentText>
          <DialogContentText pt={1}>
            <span style={{ fontWeight: theme.typography.fontWeightBold }}>
              編集中の内容がある場合は破棄されます。
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            disableElevation
            color="warning"
            onClick={() => handleChangeProject(selected)}
          >
            切り替える
          </Button>
          <Button
            variant="outlined"
            color="inherit"
            onClick={() => setIsDialogOpen(false)}
          >
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>
    </>
  ) : (
    <></>
  );
};

export default ProjectSelector;
